import SeoComponent from "@sid/core/components/SeoComponent";
import clsx from "clsx";
import { GetServerSidePropsContext } from "next";
import { Trans, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
// import ShorternerAnim from "../components/illustration/ShorternerAnim";
import NewLandingLayout from "@sid/core/components/home/NewLandingLayout";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useRef, useState } from "react";

import ClaimMicrosite from "@sid/core/components/common/ClaimMicrosite";
import NewFooter from "@sid/core/components/common/NewFooter";
import NewPublicShorten from "@sid/core/components/common/NewPublicShorten";
import MainHeading from "@sid/core/components/home/MainHeading";
import UletNongol from "@sid/core/components/home/UletNongol";
import AsFeatured from "@sid/core/components/home/landing/AsFeatured";
import LandingIllustration from "@sid/core/components/home/landing/LandingIllustration";
import LandingSection2 from "@sid/core/components/home/landing/LandingSection2";
import LandingSection2A from "@sid/core/components/home/landing/LandingSection2A";
import LandingSection3 from "@sid/core/components/home/landing/LandingSection3";
import LandingSectionShortener from "@sid/core/components/home/landing/LandingSectionShortener";
import { useUserContext } from "@sid/core/context/UserContext";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import { useRouter } from "next/router";
import useSWR from "swr";

const ShorternerAnim = dynamic(
  () => import("@sid/core/components/home/illustration/ShorternerAnim"),
  { ssr: false }
);

const MicrositeAnim = dynamic(
  () => import("@sid/core/components/home/illustration/MicrositeAnim"),
  { ssr: false }
);

const IndexPage = () => {
  const { t } = useTranslation();
  const { status } = useUserContext();
  const { asPath } = useRouter();
  const timeoutRef = useRef<any>(null);
  const timeoutDur = useRef<any>(3000);
  const isLoopThing = useRef<any>(false);
  const [stopped, setStopped] = useState(false);

  const [active, setActive] = useState(0);

  const landingPreview = useMemo(() => {
    const pl = asPath.split("#pl-");
    if (pl.length > 0) {
      return pl[1];
    }
    return null;
  }, [asPath]);

  const landingConfig = useSWR(
    () => "/landing" + (landingPreview ? "?preview_id=" + landingPreview : ""),
    {
      shouldRetryOnError: false,
    }
  );

  const stats = useSWR("/statistic/home", {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    fallbackData: {
      data: {
        countLinks: 0,
        countVisitors: 0,
        countBanned: 0,
      },
    },
  });

  const landingConf = useMemo(() => {
    if (landingConfig.data?.data) {
      return landingConfig.data?.data;
    }
  }, [landingConfig]);

  const terms = ["🏡", "Microsite", "Shortener"];
  const indexes = [0, 1, 4];
  const colors = ["!bg-blue-600", "!bg-orange-600", "!bg-red-600"];

  const titleTerms = ["best_for_name", "best_for_microsite", "best_for_short"];

  const fragmentTerms = [
    "campaign_tool",
    //...
    "bio_link",
    "invitation",
    "business",
    //...
    "your_name",
    "known_audience",
    "time_based_links",
    "protect_links",
    "qr_code",
  ];

  const bgColor = useMemo(() => {
    if (landingConf?.background_color && active === 0) {
      return landingConf?.background_color;
    }

    if (active > 3) return "#52060F";
    if (active > 0) return "#07073A";
    return "#131428";
  }, [active, landingConf]);

  const bigCardStyle = useMemo(() => {
    let styles: any = {};

    if (landingConf?.background_style) {
      styles = { ...landingConf?.background_style };
    }

    if (landingConf?.background) {
      styles.backgroundImage = `url(${landingConf?.background})`;
    }

    if (landingConf?.background_color_accent) {
      styles.backgroundColor = landingConf?.background_color_accent;
    }

    return styles;
  }, [landingConf]);

  const onClickSection = (index) => {
    timeoutDur.current = 1500;
    if (index === 4 || index === 1) {
      if (index === 1) {
        isLoopThing.current = "microsite";
      } else {
        isLoopThing.current = "shorten";
      }
      setStopped(false);
    } else {
      isLoopThing.current = false;
      clearTimeout(timeoutRef.current);
      setStopped(true);
    }
    setActive(index);
  };

  useEffect(() => {
    if (!stopped) {
      const loopThing = () => {
        setActive((s) => {
          // setting timeout duration
          if (s === 8 && isLoopThing.current !== "shorten") {
            timeoutDur.current = 3000;
          } else if (s === 0) {
            timeoutDur.current = 1500;
          } else {
            timeoutDur.current = 1500;
          }

          if (s === 8) {
            if (isLoopThing.current === "shorten") {
              return 4;
            } else {
              clearTimeout(timeoutRef.current);
              setStopped(true);
            }
            return 0;
          } else if (s === 3 && isLoopThing.current === "microsite") {
            return 1;
          } else {
            return s + 1;
          }
        });
        if (!stopped) {
          timeoutRef.current = setTimeout(loopThing, timeoutDur.current);
        }
      };
      timeoutRef.current = setTimeout(loopThing, timeoutDur.current);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [stopped]);

  return (
    <NewLandingLayout bgColor={bgColor}>
      <SeoComponent
        title={t("title_landing")}
        description={t("meta_landing")}
        images={[
          {
            url: "https://cdn-sdotid.adg.id/assets/sid-ogp.jpg",
          },
        ]}
      />
      <div className="container-xl mx-auto relative md:px-6">
        <MainHeading
          inverse={landingConf?.inverse}
          activeSection={active}
          activePage="home"
          style={bigCardStyle}
        >
          <UletNongol />
          <div className="flex flex-col lg:flex-row items-center justify-center p-4 md:p-8 lg:p-14 !pt-0 flex-1">
            <div className="w-full lg:w-6/12 order-2 lg:-order-1 text-center lg:text-left">
              <h1 className="text-xl md:text-4xl xl:text-5xl font-bold font-montserrat">
                {t("landing:welcome_to_sid")}
              </h1>
              <p className="md:text-2xl mt-3 leading-relaxed font-montserrat">
                {t(
                  `landing:welcome_to_sid_desc.${
                    titleTerms[active > 5 ? 2 : active > 0 ? 1 : 0]
                  }`
                )}{" "}
                <span className="underline font-semibold">
                  {t(`landing:welcome_to_sid_desc.${fragmentTerms[active]}`)}
                </span>
              </p>

              <div className="flex gap-2 mt-6 font-montserrat font-medium mb-3 justify-center lg:justify-start">
                {terms.map((item, i) => (
                  <button
                    className={clsx(
                      "bg-black  py-2 px-4 rounded-3xl",
                      active === 0 && landingConf?.inverse
                        ? "bg-opacity-10"
                        : "bg-opacity-30",
                      i === active && active === 0 && colors[0],
                      i === 1 && active > 0 && active < 4 && colors[1],
                      i === 2 && active > 3 && colors[2]
                    )}
                    key={i}
                    onClick={() => {
                      onClickSection(indexes[i]);
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
            <div className="w-full lg:w-6/12 flex flex-col items-center justify-center relative">
              <div className="pb-[100%] md:pb-[70%] w-full" />
              <LandingIllustration active={active === 0} values={landingConf} />

              <div
                className={clsx(
                  "absolute transition-all",
                  active > 0 && active < 4 ? "opacity-1" : "opacity-0"
                )}
              >
                <MicrositeAnim index={active} />
              </div>

              <div
                className={clsx(
                  "absolute transition-all",
                  active > 3 && active < 9 ? "opacity-1" : "opacity-0 scale-75"
                )}
              >
                <ShorternerAnim index={active - 4} />
              </div>
            </div>
          </div>
        </MainHeading>

        {status === "unauthorized" && (
          <div className={clsx(active > 0 && "pb-20 lg:pb-0")}>
            <div
              className={clsx(
                "transition-all absolute w-full left-0 right-0 duration-500",
                active > 0 && active < 4
                  ? "translate-y-28 lg:translate-y-5"
                  : "translate-y-20 lg:-translate-y-5 opacity-0 overflow-hidden max-h-0"
              )}
            >
              <h1 className="text-white text-xl md:text-2xl font-montserrat font-bold text-center -mt-24 mb-3">
                <Trans i18nKey="landing:microsite_feat.heading" />
              </h1>
              <ClaimMicrosite
                onFocus={() => {
                  isLoopThing.current = "microsite";
                  setStopped(false);
                }}
              />
            </div>

            <div
              className={clsx(
                "transition-all absolute w-full left-0 right-0 duration-500",
                active > 3
                  ? "translate-y-28 lg:translate-y-5"
                  : "translate-y-20 lg:-translate-y-5 opacity-0 overflow-hidden max-h-0"
              )}
            >
              <h1 className="text-white text-xl md:text-2xl font-montserrat font-bold text-center -mt-24 mb-3">
                <Trans i18nKey="landing:shorten_feat.heading" />
              </h1>
              <NewPublicShorten
                onFocus={() => {
                  isLoopThing.current = "shorten";
                  setStopped(false);
                }}
              />
            </div>
          </div>
        )}

        <LandingSection2 />

        <LandingSection2A />

        <LandingSection3 />

        <LandingSectionShortener />

        {/* <LandingSection4 /> */}

        <section className="mt-16 md:mt-28 text-white flex items-center flex-col md:flex-row hidden">
          <div className="md:w-7/12 md:text-xl leading-loose font-worksans">
            <h1 className="font-extrabold font-montserrat text-2xl md:text-6xl text-center md:text-left">
              <Trans i18nKey={"landing:what_is_sid"} />
            </h1>
            <div className="mt-3 md:mt-6 px-6 md:px-0 leading-loose text-center md:text-left">
              <Trans i18nKey={"landing:what_is_sid_desc"} />
            </div>
          </div>
          <div className="md:w-5/12 px-6 md:px-0 pt-6 md:pt-0">
            <img src={assetPrefix + "images/landing/what-sid.png"} alt="s.id" />
          </div>
        </section>

        <section className="mt-10 md:mt-28 text-white flex flex-wrap hidden">
          <div className="w-full px-6">
            <h1 className="font-extrabold font-montserrat text-2xl md:text-5xl text-center">
              <Trans i18nKey={"landing:feature_highlights"} />
            </h1>
            <p className="text-center font-montserrat md:text-2xl mt-4">
              <Trans i18nKey={"landing:feature_highlights_desc"} />
            </p>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-6 mt-20 px-6 md:px-0">
            <div className="md:w-4/12 text-xl text-center leading-loose font-worksans bg-white rounded-3xl text-black p-6 mb-8 md:mb-0">
              <div className="flex w-full justify-center -mt-14 md:-mt-16">
                <div className="w-16 h-16 md:w-24 md:h-24 bg-white rounded-full shadow-xl flex justify-center items-center p-2 md:p-3">
                  <img
                    src={assetPrefix + "images/landing/feature-links.svg"}
                    alt="s.id links"
                  />
                </div>
              </div>
              <h1 className="text-xl md:text-3xl font-montserrat font-bold mb-3 mt-3 md:mt-6">
                <Trans i18nKey="landing:feature_highlights_feat.link_shortener" />
              </h1>
              <p>
                <Trans i18nKey="landing:feature_highlights_feat.link_shortener_desc" />
              </p>
            </div>
            <div className="md:w-4/12 text-xl text-center leading-loose font-worksans bg-white rounded-3xl text-black p-6 mb-8 md:mb-0">
              <div className="flex w-full justify-center -mt-14 md:-mt-16">
                <div className="w-16 h-16 md:w-24 md:h-24 bg-white rounded-full shadow-xl flex justify-center items-center p-2 md:p-4">
                  <img
                    src={assetPrefix + "images/landing/feature-microsite.svg"}
                    alt="s.id links"
                  />
                </div>
              </div>
              <h1 className="text-xl md:text-3xl font-montserrat font-bold mb-3 mt-3 md:mt-6">
                <Trans i18nKey="landing:feature_highlights_feat.microsite_builder" />
              </h1>
              <p>
                <Trans i18nKey="landing:feature_highlights_feat.microsite_builder_desc" />
              </p>
            </div>
            <div className="md:w-4/12 text-xl text-center leading-loose font-worksans bg-white rounded-3xl text-black p-6 mb-8 md:mb-0">
              <div className="flex w-full justify-center -mt-14 md:-mt-16">
                <div className="w-16 h-16 md:w-24 md:h-24 bg-white rounded-full shadow-xl flex justify-center items-center p-2 md:p-2">
                  <img
                    src={assetPrefix + "images/landing/feature-analytics.svg"}
                    alt="s.id links"
                  />
                </div>
              </div>
              <h1 className="text-xl md:text-3xl font-montserrat font-bold mb-3 mt-3 md:mt-6">
                <Trans i18nKey="landing:feature_highlights_feat.analytics" />
              </h1>
              <p>
                <Trans i18nKey="landing:feature_highlights_feat.analytics_desc" />
              </p>
            </div>
          </div>
        </section>

        <section className="text-white mt-4 md:mt-16">
          <h1 className="font-extrabold font-montserrat text-2xl md:text-5xl text-center">
            <Trans i18nKey="landing:as_featured_in" />
          </h1>
          <div className="bg-white rounded-3xl mt-6 md:mt-10 py-4 md:py-10 px-3 mx-6 md:mx-0">
            <AsFeatured />
          </div>
        </section>

        <section className="text-white mt-10 md:mt-16">
          <h1 className="font-extrabold font-montserrat text-2xl md:text-5xl text-center mb-6 md:mb-20">
            <Trans i18nKey="landing:statistics_feat.heading" />
          </h1>

          <div className="flex md:gap-4 flex-wrap">
            <div className="text-center w-6/12 md:flex-1 p-6">
              <div className="text-4xl md:text-7xl font-bold font-montserrat">
                {stats.isValidating ? (
                  <div className="w-40 h-20 rounded-md bg-white animate-pulse inline-block" />
                ) : (
                  Intl.NumberFormat("en", { notation: "compact" }).format(
                    //@ts-ignore
                    stats.data?.data?.countLinks || 0
                  )
                )}
              </div>
              <h2 className="md:text-xl mt-4">
                <Trans i18nKey="landing:statistics_feat.link" />
              </h2>
            </div>
            <div className="text-center w-6/12 md:flex-1 p-6">
              <div className="text-4xl md:text-7xl font-bold font-montserrat">
                {stats.isValidating ? (
                  <div className="w-40 h-20 rounded-md bg-white animate-pulse inline-block" />
                ) : (
                  Intl.NumberFormat("en", { notation: "compact" }).format(
                    //@ts-ignore
                    stats.data?.data?.countMicrosite || 0
                  )
                )}
              </div>
              <h2 className="md:text-xl mt-4">
                <Trans i18nKey="landing:statistics_feat.microsite" />
              </h2>
            </div>
            <div className="text-center w-6/12 md:flex-1 p-6">
              <div className="text-4xl md:text-7xl font-bold font-montserrat">
                {stats.isValidating ? (
                  <div className="w-40 h-20 rounded-md bg-white animate-pulse inline-block" />
                ) : (
                  Intl.NumberFormat("en", { notation: "compact" }).format(
                    //@ts-ignore
                    stats.data?.data?.countVisitors || 0
                  )
                )}
              </div>
              <h2 className="md:text-xl mt-4">
                <Trans i18nKey="landing:statistics_feat.visitor" />
              </h2>
            </div>
            <div className="text-center w-6/12 md:flex-1 p-6">
              <div className="text-4xl md:text-7xl font-bold font-montserrat">
                24<small className="text-2xl hidden">hr</small>/7
              </div>
              <h2 className="md:text-xl mt-4">
                <Trans i18nKey="landing:statistics_feat.phised" />
              </h2>
            </div>
          </div>
        </section>

        <section className="mt-16">
          <div className="bg-white text-gray-800 p-8 rounded-3xl mx-6 md:mx-0">
            <h1 className="font-extrabold font-montserrat text-2xl md:text-4xl text-center">
              <Trans i18nKey="integrated_with">Integrated with</Trans>
            </h1>
            <div className="flex gap-3 items-center justify-center my-5">
              <a
                href="https://taptap.id"
                target="_blank"
                className="hover:opacity-75"
              >
                <img
                  src={assetPrefix + "images/taptap-logo.svg"}
                  alt="Taptap"
                  className="max-h-[80px]"
                />
              </a>

              <a
                href="https://klip.id"
                target="_blank"
                className="hover:opacity-75"
              >
                <img
                  src={assetPrefix + "images/klip-id-logo.svg"}
                  alt="Klip.id"
                  className="max-h-[50px] mt-3"
                />
              </a>
            </div>
          </div>
        </section>

        <section className="mt-16">
          <div className="bg-white text-gray-800 p-8 rounded-3xl mx-6 md:mx-0">
            <h1 className="font-extrabold font-montserrat text-2xl md:text-4xl text-center">
              <Trans i18nKey="landing:official_partner.heading" />
            </h1>
            <div className="flex gap-1 items-center justify-center my-5">
              <a
                href="https://pandi.id"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-75"
              >
                <img
                  src={assetPrefix + "images/pandi.png"}
                  alt="Pandi"
                  className="max-w-[250px]"
                />
              </a>
            </div>
            <div className="text-center">
              <p className="md:text-xl">
                <a href="mailto:marketing@adg.id" className="underline">
                  <Trans i18nKey="landing:official_partner.contact" />
                </a>
              </p>
            </div>
          </div>
        </section>

        <div className="content-info text-xs text-white mt-10 leading-relaxed -mb-10 hidden">
          <h1 className="font-bold mb-3">
            s.id pemendek tautan dan pembuat microsite
          </h1>
          <h2 className="font-bold">Perpendek Tautan Anda dengan s.id</h2>
          <p className="text-white text-opacity-70">
            Ubah tautan panjang menjadi tautan pendek yang ringkas dan mudah
            dibagikan menggunakan shortener s.id. Sempurna untuk media sosial,
            email, pendidikan, toko online, toko offline, warung, UMKM, dan
            banyak lagi. Mulailah memperpendek tautan Anda hari ini di s.id.
          </p>

          <h2 className="mt-3 font-bold">
            Buat Microsite dengan s.id Bio Link
          </h2>
          <p className="text-white text-opacity-70">
            Bangun microsite pribadi yang menggabungkan semua tautan penting
            Anda di satu tempat. Ideal untuk profil media sosial, kartu nama,
            portofolio, pendidikan, toko online, toko offline, warung, dan UMKM.
            Buat s.id Bio Link Anda dan tingkatkan kehadiran online Anda
            sekarang.
          </p>

          <h2 className="mt-3 font-bold">Mengapa Memilih s.id?</h2>
          <p className="text-white text-opacity-70">
            s.id menawarkan layanan perpendek tautan yang andal dan cepat dengan
            manfaat tambahan seperti analitik mendetail dan URL yang dapat
            disesuaikan. Optimalkan pengelolaan tautan Anda dengan s.id hari
            ini, baik Anda di bidang pendidikan, menjalankan toko online atau
            offline, mengelola warung, atau mendukung UMKM.
          </p>

          <h2 className="mt-3 font-bold">Jelajahi Lebih Banyak dengan s.id</h2>
          <p className="text-white text-opacity-70">
            Tetap terdepan dalam dunia digital dengan fitur-fitur canggih s.id
            untuk manajemen tautan. Apakah Anda ingin memperpendek URL, membuat
            microsite khusus, atau mendukung upaya pendidikan, toko online, toko
            offline, warung, atau UMKM, s.id menyediakan alat yang Anda butuhkan
            untuk sukses online.
          </p>
        </div>

        <NewFooter />
      </div>
    </NewLandingLayout>
  );
};

export default IndexPage;

export async function getServerSideProps({
  locale,
}: GetServerSidePropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale || "en", ["common", "landing"])),
    },
  };
}
